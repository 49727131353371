/**
 * TODO: Should we use Element UI notifications instead?
 */
import toastr from "toastr";

toastr.options = {
    "closeButton":       false,
    "debug":             false,
    "newestOnTop":       false,
    "progressBar":       false,
    "preventDuplicates": true,
    "escapeHtml":        true,
    "onclick":           null,
    "showDuration":      "200",
    "hideDuration":      "1000",
    "timeOut":           "8000",
    "extendedTimeOut":   "10000",
    "showEasing":        "swing",
    "hideEasing":        "linear",
    "showMethod":        "slideDown",
    "hideMethod":        "slideUp",
    "positionClass":     "toast-bottom-center",
};

export function success(message) {
    toastr.success(message);
}

export function info(message) {
    toastr.info(message);
}

export function error(message) {
    toastr.error(message);
}

export function danger(message) {
    toastr.warning(message);
}

export function warning(message) {
    toastr.warning(message);
}

export function onClick(callback) {
    toastr.options.onclick = callback;
}
export function clear() {
    toastr.clear();
}
